import axios from 'axios';
import {config} from "../../config";

export const getGeolocation = async (cellTowers: [], wifiAccessPoints = []) => {
    const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${config.googleApiKey}`;

    try {
        const response = await axios.post(url, {
            considerIp: 'true',
            cellTowers, wifiAccessPoints
        });

        return response.data.location;
    } catch (error) {
        console.error("Geolocation API error:", error);
        throw error
    }
}

export const persistPlaybackData = async (data: any) => {

    const url = 'https://europe-west3-player-logos.cloudfunctions.net/process-playback';
    try {
        const response = await axios.post(url, data);

        console.log(response);
    } catch (error) {
        console.error("Playback API error:", error);
        throw error
    }
};